var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact"},[_c('div',{staticClass:"main"},[_c('h1',[_vm._v("تماس با ما")]),_c('div',{staticClass:"content"},[_vm._m(0),_c('div',{staticClass:"information"},[_c('div',{staticClass:"header"},[_vm._v("اطلاعات تماس")]),_c('div',{staticClass:"body"},[_c('ul',[_c('li',{staticClass:"address"},[_c('a',[_vm._v("آدرس : "+_vm._s(_vm.info.address))])]),_c('li',{staticClass:"phone"},[_c('a',[_vm._v("تلفن : "+_vm._s(_vm.info.number))])]),_c('li',{staticClass:"email"},[_c('a',[_vm._v("ایمیل : "+_vm._s(_vm.info.email))])])])]),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"social"},[_c('a',[_vm._v(" شبکه های اجتماعی:")]),_c('div',{staticClass:"social"},[_c('a',{attrs:{"href":_vm.info.twitter.includes('http')
                    ? _vm.info.twitter
                    : ("https://twitter.com/" + (_vm.info.twitter)),"target":"_blank"}},[_c('img',{attrs:{"src":require("../../assets/icons/Twitter.svg"),"alt":"Twitter"}})]),_c('a',{attrs:{"href":_vm.info.telegram.includes('http')
                    ? _vm.info.telegram
                    : ("https://telegram.org/" + (_vm.info.telegram)),"target":"_blank"}},[_c('img',{attrs:{"src":require("../../assets/icons/Telegram.svg"),"alt":"Telegram"}})]),_c('a',{attrs:{"href":_vm.info.linkedin.includes('http')
                    ? _vm.info.linkedin
                    : ("https://linkedin.com/" + (_vm.info.linkedin)),"target":"_blank"}},[_c('img',{attrs:{"src":require("../../assets/icons/LinkedIn.svg"),"alt":"LinkedIn"}})]),_c('a',{attrs:{"href":_vm.info.instagram.includes('http')
                    ? _vm.info.instagram
                    : ("https://instagram.com/" + (_vm.info.instagram)),"target":"_blank"}},[_c('img',{attrs:{"src":require("../../assets/icons/Instagram.svg"),"alt":"Instagram"}})])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form"},[_c('label',{attrs:{"for":"name"}},[_vm._v("نام و نام خانوادگی")]),_c('input',{attrs:{"type":"text","name":"name","id":"name"}}),_c('label',{attrs:{"for":"email"}},[_vm._v("ایمیل")]),_c('input',{attrs:{"type":"text","name":"email","id":"email"}}),_c('label',{attrs:{"for":"context"}},[_vm._v("متن پیام")]),_c('textarea',{attrs:{"name":"context","id":"context"}}),_c('button',[_vm._v("ارسال")])])}]

export { render, staticRenderFns }