var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box1"},[_c('div',{staticClass:"content"},[_c('select',{attrs:{"name":"","id":""}},[_c('option',{attrs:{"value":""}},[_vm._v(" بازار "+_vm._s(_vm.$coinLabel[_vm.$route.params.tradeFrom])+" - "+_vm._s(_vm.$coinLabel[_vm.$route.params.tradeTo])+" ")]),_c('option',{attrs:{"value":""}}),_c('option',{attrs:{"value":""}}),_c('option',{attrs:{"value":""}})])]),_c('div',{staticClass:"main-content"},[_c('a',[_vm._v("بیشترین")]),_c('a',{staticClass:"content",staticStyle:{"color":"#00af8d"}},[_vm._v(_vm._s(_vm.realprice.top.toLocaleString("en-US"))),_c('a',{staticStyle:{"color":"#00af8d"}},[_vm._v(_vm._s(_vm.$coinLabel[_vm.$route.params.tradeTo]))])])]),_c('div',{staticClass:"main-content"},[_c('a',[_vm._v("کمترین")]),_c('a',{staticClass:"content",staticStyle:{"color":"#f5484f"}},[_vm._v(_vm._s(_vm.realprice.down.toLocaleString("en-US"))+" "),_c('a',{staticStyle:{"color":"#f5484f"}},[_vm._v(_vm._s(_vm.$coinLabel[_vm.$route.params.tradeTo]))])])]),_c('div',{staticClass:"main-content"},[_c('a',[_vm._v("آخرین")]),_c('a',{staticClass:"content"},[_vm._v(_vm._s(_vm.realprice.last.toLocaleString("en-US"))),_c('a',[_vm._v(_vm._s(_vm.$coinLabel[_vm.$route.params.tradeTo]))])])]),_c('div',{staticClass:"main-content"},[_c('a',[_vm._v("حجم")]),_c('a',{staticClass:"content"},[_vm._v(_vm._s(_vm.realprice.vol.toLocaleString("en-US"))+" "),_c('a',[_vm._v(_vm._s(_vm.$coinLabel[_vm.$route.params.tradeTo]))])])]),_c('div',{staticClass:"main-content"},[_c('a',[_vm._v("تغییرات")]),_c('a',{staticClass:"content",style:({
        color: [
          _vm.realprice.change > 0
            ? 'var(--main-green)'
            : _vm.realprice.change < 0
            ? 'var(--main-red)'
            : '' ],
      })},[_vm._v(" "+_vm._s(_vm.realprice.change)+"٪ ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }