var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav"},[_c('button',{staticClass:"close",on:{"click":_vm.closeMenu}}),_c('ul',[_c('li',[_c('router-link',{attrs:{"to":'/'},on:{"click":_vm.closeMenu}},[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/logo.png"),"alt":"firebex","width":"125","height":"47"}})])],1),_vm._m(0)]),_c('ul',{staticClass:"left-side"},[_c('li',{staticClass:"time"},[_vm._v(_vm._s(_vm.now))]),_c('li',[_c('router-link',{attrs:{"to":'/notifications'},nativeOn:{"click":function($event){return _vm.closeMenu.apply(null, arguments)}}},[_c('div',{class:[
            ['Notifications'].includes(_vm.$route.name)
              ? 'notification_active'
              : 'notification' ]})])],1),_c('li',[_c('router-link',{attrs:{"to":'/settings'},nativeOn:{"click":function($event){return _vm.closeMenu.apply(null, arguments)}}},[_c('div',{class:[
            ['Settings'].includes(_vm.$route.name)
              ? 'settings_active'
              : 'settings' ]})])],1),_c('li',{staticClass:"profile_btn"},[_c('router-link',{attrs:{"to":'/profile'},nativeOn:{"click":function($event){return _vm.closeMenu.apply(null, arguments)}}},[_c('div',{class:[
            ['Profile'].includes(_vm.$route.name) ? 'profile_active' : 'profile' ]}),_c('span',{staticClass:"title_profile"},[_vm._v(" احراز هویت ")])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('div',{staticClass:"wallet"},[_c('div',{staticClass:"icon"}),_c('div',{staticClass:"balance"},[_vm._v("موجودی شما: ۲۳۴,۳۴۵,۸۵۹")]),_c('div',{staticClass:"buy"}),_c('div',{staticClass:"sell"})])])}]

export { render, staticRenderFns }