var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"hamburger-btn"},[_c('button',{staticClass:"menu",on:{"click":_vm.showMenu}}),(
        ![
          'Login',
          'Register',
          'Forgot',
          'Home',
          'About',
          'Policy',
          'Contact',
          'Faq' ].includes(_vm.$route.name)
      )?_c('div',{staticClass:"shortcuts"},[_c('ul',{staticClass:"navbar"},[_c('li',{class:[_vm.$route.name == 'Wallet' ? 'active_nav' : '']},[_c('router-link',{attrs:{"to":'/wallet'}},[_c('div',{staticClass:"wallet_nav icon"}),_vm._v(" کیف پول")])],1),_c('li',{class:[_vm.$route.name == 'Exchange' ? 'active_nav' : '']},[_c('router-link',{attrs:{"to":'/exchange'}},[_c('div',{staticClass:"exchange_nav icon"}),_vm._v(" مارکت")])],1),_c('li',{class:[_vm.$route.params.tradeTo == 'TOMAN' ? 'active_nav' : '']},[_c('router-link',{attrs:{"to":'/trade/TOMAN/BITCOIN'}},[_c('div',{staticClass:"market icon"}),_vm._v(" بازار پایه ریال")])],1),_c('li',{class:[_vm.$route.params.tradeTo == 'TETHER' ? 'active_nav' : '']},[_c('router-link',{attrs:{"to":'/trade/TETHER/BITCOIN'}},[_c('div',{staticClass:"advance icon"}),_vm._v(" بازار پایه تتر")])],1)])]):_vm._e()]),(
      [
        'Login',
        'Register',
        'Forgot',
        'Home',
        'About',
        'Policy',
        'Contact',
        'Faq' ].includes(_vm.$route.name)
    )?_c('div',{attrs:{"id":"nav"}},[_c('navbar')],1):_vm._e(),(
      ![
        'Login',
        'Register',
        'Forgot',
        'Home',
        'About',
        'Policy',
        'Contact',
        'Faq' ].includes(_vm.$route.name)
    )?_c('div',{attrs:{"id":"side"}},[_c('navbar-dashboard'),_c('sidebar')],1):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[_c('router-view')],1),(
      [
        'Login',
        'Register',
        'Forgot',
        'Home',
        'About',
        'Policy',
        'Contact',
        'Faq' ].includes(_vm.$route.name)
    )?_c('div',{attrs:{"id":"footer"}},[_c('Footer')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }