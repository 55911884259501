var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main dark"},[_c('div',{staticClass:"trade-body"},[_c('div',{staticClass:"trading"},[_c('trade-header',{attrs:{"realprice":_vm.maintable}}),_c('div',{staticClass:"order-first order-sm-12",staticStyle:{"padding":"10px 0","width":"100%"}},[_c('vue-trading-view',{key:_vm.$coinUnit[_vm.$route.params.tradeFrom],staticStyle:{"height":"377px"},attrs:{"options":{
            autosize: true,
            symbol: 'BINANCE:' + _vm.$coinUnit[_vm.$route.params.tradeFrom] + 'USDT',
            timezone: 'Asia/Tehran',
            theme: 'light',
            style: '1',
            theme: 'light',
            locale: 'fa_IR',
            enable_publishing: true,
            withdateranges: true,
            range: 'ytd',
            hide_side_toolbar: false,
            allow_symbol_change: true,
            enabled_features: ['header_fullscreen_button'],
            disabled_features: [
              'border_around_the_chart',
              'remove_library_container_border' ],
            loading_screen: {
              backgroundColor: '#ffffff',
              foregroundColor: '#000000',
            },
            overrides: {
              background: '#256',
            },
          }}}),_c('shop')],1)],1),_c('div',{staticClass:"trade-left"},[_c('real-price',{staticClass:"box",attrs:{"tomanTable":_vm.realpricetoman,"teterTable":_vm.realpriceteter}}),_c('last-trade',{staticClass:"box"})],1)]),_c('div',{staticClass:"depth"},[_c('buy-depth',{attrs:{"table-contents":_vm.depthTableBuy}}),_c('sell-depth',{attrs:{"table-contents":_vm.depthTableSell}})],1),_c('div',{staticClass:"orders-btm"},[_c('div',{staticClass:"tabs"},[_c('div',{class:[!_vm.active ? 'tab-content' : 'tab-content-active buy-tab'],on:{"click":function($event){$event.preventDefault();return _vm.switchTab(true)}}},[_vm._v(" سفارشات فعال من ")]),_c('div',{class:[_vm.active ? 'tab-content' : 'tab-content-active sell-tab'],on:{"click":function($event){$event.preventDefault();return _vm.switchTab(false)}}},[_vm._v(" آخرین سفارشات من ")])]),(_vm.active)?_c('active-order',{staticClass:"box",staticStyle:{"flex-basis":"300px","flex-grow":"1","width":"100%","background-color":"#fdfdfd","height":"auto","overflow-x":"scroll","direction":"rtl"}}):_c('last-order',{staticClass:"box",staticStyle:{"flex-basis":"300px","flex-grow":"1","width":"100%","background-color":"#fdfdfd","height":"auto","overflow-x":"scroll","direction":"rtl"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }