var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!['Login', 'Register', 'Forgot'].includes(_vm.$route.name))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.logedin || _vm.home),expression:"!logedin || home"}],staticClass:"footer"},[_c('div',{staticClass:"footer-content row ml-2 mr-2"},[_vm._m(0),_c('div',{staticClass:"footer-list col-sm-3 col-6 pr-3 pr-md-5"},[_c('div',[_c('a',[_vm._v("درباره")]),_c('ul',[_c('li',[_c('a',{on:{"click":function($event){return _vm.$router.push('about-us')}}},[_vm._v("درباره ما")])]),_c('li',[_c('a',{on:{"click":function($event){return _vm.$router.push('privacy-policy')}}},[_vm._v("قوانین و مقررات")])])])])]),_c('div',{staticClass:"footer-list col-sm-3 col-6"},[_c('div',[_c('a',[_vm._v("راهنما")]),_c('ul',[_c('li',[_c('a',{on:{"click":function($event){return _vm.$router.push('faq')}}},[_vm._v("راهنمای استفاده")])]),_vm._m(1)])])]),_c('div',{staticClass:"footer-list col-sm-3 col-6"},[_c('div',{staticStyle:{"width":"100%"}},[_c('a',[_vm._v("اطلاعات تماس")]),_c('ul',[_c('li',{staticClass:"address"},[_c('a',[_vm._v("آدرس : "+_vm._s(_vm.info.address))])]),_c('li',{staticClass:"phone"},[_c('a',[_vm._v("تلفن : "+_vm._s(_vm.info.number))])]),_c('li',{staticClass:"email"},[_c('a',[_vm._v("ایمیل : "+_vm._s(_vm.info.email))])])])])])]),_c('div',{staticClass:"footer-social"},[_c('div',{staticClass:"row m-2 no-gutters"},[_vm._m(2),_c('div',{staticClass:"\n          col-md-6 col-12\n          social\n          justify-content-md-end justify-content-center\n          row\n          no-gutters\n          m-0\n        "},[_c('a',{staticClass:"col-12 col-md-4"},[_vm._v(" شبکه های اجتماعی:")]),_c('div',{staticClass:"\n            social\n            col-6 col-md-4\n            justify-content-around justify-content-sm-between\n          "},[_c('a',{attrs:{"href":_vm.info.twitter.includes('http')
                ? _vm.info.twitter
                : ("https://twitter.com/" + (_vm.info.twitter)),"target":"_blank"}},[_c('img',{attrs:{"src":require("../../assets/icons/Twitter.svg"),"alt":"Twitter"}})]),_c('a',{attrs:{"href":_vm.info.telegram.includes('http')
                ? _vm.info.telegram
                : ("https://telegram.org/" + (_vm.info.telegram)),"target":"_blank"}},[_c('img',{attrs:{"src":require("../../assets/icons/Telegram.svg"),"alt":"Telegram"}})]),_c('a',{attrs:{"href":_vm.info.linkedin.includes('http')
                ? _vm.info.linkedin
                : ("https://linkedin.com/" + (_vm.info.linkedin)),"target":"_blank"}},[_c('img',{attrs:{"src":require("../../assets/icons/LinkedIn.svg"),"alt":"LinkedIn"}})]),_c('a',{attrs:{"href":_vm.info.instagram.includes('http')
                ? _vm.info.instagram
                : ("https://instagram.com/" + (_vm.info.instagram)),"target":"_blank"}},[_c('img',{attrs:{"src":require("../../assets/icons/Instagram.svg"),"alt":"Instagram"}})])])])])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-sm-3 col-6"},[_c('img',{attrs:{"src":require("../../assets/vistalogo.png"),"alt":"logo"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('a',{attrs:{"href":"/blog"}},[_vm._v("بلاگ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n          col-md-6 col-12\n          justify-content-center justify-content-md-start\n        ",staticStyle:{"cursor":"default","display":"flex"}},[_vm._v(" کلیه حقوق این سایت متعلق به شرکت ویستا می‌باشد. "),_c('span',[_vm._v(" طراحی شده توسط شرکت "),_c('a',{attrs:{"href":"https://vistaapp.ir"}},[_vm._v("ویستا")])])])}]

export { render, staticRenderFns }